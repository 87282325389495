import { Box, Grid, TableCell, TableRow, Breadcrumbs, Link } from '@mui/material';
import dayjs from 'dayjs';
import Joi from 'joi';
import _ from 'lodash';
import { useContext, useEffect, useMemo, useState, useRef } from 'react';
import { Controller, useFieldArray, useForm, } from "react-hook-form";
import { useNavigate, useParams, Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as DeleteIcon } from '../../../assets/delete-icon.svg';
import {
    Button,
    DataTable,
    PageWrapper,
    Tab2,
    TableStatusFilter,
    TextField,
    Typography,
    Form
} from '../../../components/share';
import constants from '../../../constants';
import { snackbarContext } from '../../../context/SnackbarProvider';
import { userContext } from "../../../context/UserProvider";
import { ClientInfoFields, PlanInfoFields, ExtraInfoFields, PaymentFields, DeliveryMethodFields, ExternalRemarks } from './formFields';
import {
    // STATUS,
    activityLogSchema,
    generalActivityLogColumns,
    DefRemark
} from './index';
import { updateOrder, addOrderItemRemark, viewDocumentById } from '../../../apis/order';
import {
    useMutation,
    useQueryClient
} from '@tanstack/react-query'
import { useTheme } from '@mui/material/styles';
import ViewUploadFileDialog from '../../../components/order/ViewUploadFileDialog';

const STATUS = [
    {
        _id: "pending",
        name: "Pending",
        disabled: true
    },
    {
        _id: "paymentSuccess",
        name: "Payment Success",
        disabled: true
    },
    {
        _id: "paymentFailure",
        name: "Payment Failure",
        disabled: true
    },
    {
        _id: "pendingDocument",
        name: "Pending Document",
        disabled: true
    },
    {
        _id: "documentSubmitted",
        name: "Document Submitted", //TODO: product: Submitted, mobile: Document Submitted
    },
    {
        _id: "verified",
        name: "Verified",
    },
    {
        _id: "cancelled",
        name: "Cancelled",
    },
    {
        _id: "completed",
        name: "Completed",
    },
]

const MNP_OPERATOR_LIST = [
    { _id: "ch", name: "CH - China HongKong Telecom" },
    { _id: "cm", name: "CM - China Motion Telecom(HK) Ltd" },
    { _id: "ct", name: "CT - CT CITIC Telecom 1616 Ltd" },
    { _id: "cu", name: "CU - China Unicom International Ltd" },
    { _id: "fa", name: "FA - Fix Network Group Admin" },
    { _id: "h3", name: "H3 - Hutchison 3G HK Ltd" },
    { _id: "hc", name: "HC - HGC (Hutchison Global Crossing)" },
    { _id: "hd", name: "HD - Hutchison CDMA" },
    { _id: "hg", name: "HG - Hutchison GSM" },
    { _id: "hp", name: "HP - Hutchison PCS" },
    { _id: "m3", name: "M3 - Sunday 3G(HK) Ltd" },
    { _id: "mp", name: "MP - Sunday (Mandarin PCS)" },
    { _id: "np", name: "NP - New World Mobility" },
    { _id: "pp", name: "PP - China Mobile Hong Kong Limited (People PCS)" },
    { _id: "s3", name: "S3 - Smartone 3G Ltd" },
    { _id: "sg", name: "SG - Smartone GSM" },
    { _id: "sp", name: "SP - Smartone PCS" },
    { _id: "t3", name: "T3 - HK CSL 3G Ltd" },
    { _id: "tc", name: "TC - Hong Kong Telecom" },
    { _id: "tg", name: "TG - HKTCSL GSM" },
    { _id: "tp", name: "TP - HKTCSL PCS" },
    { _id: "tv", name: "TV - Trident Telecom Ventures Ltd" },
    { _id: "sm", name: "SM - Sun Mobile" },
    { _id: "td", name: "TD - Telecom Digital Mobile LTD" },
    { _id: "hb", name: "HB - Hong Kong Broadband Mobile" },
    { _id: "truphone", name: "MVNO - Truphone (Hong Kong) Limited" },
    { _id: "birdie", name: "MVNO - Birdie Mobile Limited" },
    { _id: "others", name: "Others" },
]

const SaveButton = styled(Button)`
    && {
        align-self: end; 
        min-width: 80px;
        margin: 10px 8px;
        height: 30px;
    }
`

export const EditButton = styled(Button)`
    && {
        align-self: end; 
        width: 80px;
        margin: 10px 20px;
    }
`

export const Content = styled.div`
    padding: 20px;
    overflow: auto;
    height: 100%;
`

const TABS = [
    {
        label: "Plan Information",
        id: "planInformation"
    },
    {
        label: "Payment",
        id: "payment"
    },
    {
        label: "Delivery Method",
        id: "delivery"
    },
    {
        label: "Customer Information",
        id: "clientInformation",
    },
    {
        label: "Documents Upload",
        id: "documentUpload"
    },
    {
        label: "External Remarks",
        id: "externalRemarks",
        canEdit: true
    },
    {
        label: "Activity Log",
        id: "activityLog"
    },
    {
        label: "Extra Info",
        id: "extraInfo",
        canEdit: true
    }
]

const DocumentType = [
    {
        id: "passport",
        name: "HKID / Passport Copy"
    },
    {
        id: "addressProof",
        name: "Address Proof"
    },
    {
        id: "mnpForm",
        name: "Mobile No. Portability (MNP)"
    },
    {
        id: "simCardImage",
        name: "SIM ICCID Proven"
    },
    {
        id: "changeNamePassport",
        name: "Change Name Passport"
    }
]

const schema = Joi.object({
    name: {
        en: Joi.string().empty(""),
        zh: Joi.string().empty("")
    },
    email: Joi.string(),
    role: Joi.any(),
});

const DocUploadedColumns = ({control, onViewDocument}) => [
    {
        dataKey: 'documentType',
        label: 'Document',
        width: 300,
        renderCell: (item, index) => {
            const docType = DocumentType.find((doc) => doc.id === item.documentType)
            return (
                <Box display="flex" flexDirection="column">
                    <Typography color="primary.dark" bold>{docType?.name}</Typography>
                </Box>
            )
        }
    },
    {
        dataKey: "status",
        label: "Status",
        width: 300,
        renderCell: (item, index) => {
            const status = (!!item?.filename && item?.docStatus === "pendingApproval") ? "Uploaded" : "Not Uploaded"
            return (
                <Typography color="primary.dark">{status}</Typography>
            )
        }
    },
    {
        dataKey: 'action',
        label: 'Action',
        renderCell: (item, index) => {
            const canPreview = !!item?.filename && item?.docStatus === "pendingApproval";
            return (
                <Box display="flex" flexDirection="row">
                    <Button
                        sx={{ height: 35 }}
                        disabled={!canPreview}
                        onClick={() => onViewDocument && onViewDocument(item)}
                    >
                        Preview
                    </Button>
                </Box>
            )
        }
    },
]

function MobileDetail({ data, permission, pIIPermission, isLoading }) {

    const navigate = useNavigate();
    const theme = useTheme();

    const [tab, setTab] = useState(0);
    const [isFetchingDocument, setIsFetchingDocument] = useState(false);
    const [snackbar, setSnackbar] = useContext(snackbarContext);
    const [user, setUser] = useContext(userContext);

    const viewUploadFileDialogRef = useRef();

    let { orderItemId } = useParams();

    const queryClient = useQueryClient()

    const { canView, canModify } = permission
    const { canView: canViewPII } = pIIPermission

    const { control, handleSubmit, watch, formState: { errors, dirtyFields }, reset, getValues } = useForm({
        // resolver: joiResolver(schema),
    });

    // activity log field array
    const ActivityLogTab = TABS.find((tab) => tab.id === "activityLog")
    const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
        control,
        name: `${ActivityLogTab.id}.remarks`,
    });

    const MNP_EFFECTIVE_TIME = {
        am: "1am - 4am",
        pm: "12noon - 2pm"
    }

    const onApiError = (error) => {
        setSnackbar({
            type: 'error',
            message: `${error.code} ${error.errorMessage ? `- ${error.errorMessage?.en}` : ""}`
        })
    }

    useEffect(() => {
        if (data) {
            const payload = {
                planInformation: {
                    orderNumber: data?.orderNumber,
                    plan: {
                        ...data?.plan,
                        newNumbers: data?.plan?.newNumbers?.[0],
                        mnpNumbers: data?.plan?.mnpNumbers?.[0],
                        contractPeriod: data?.plan?.contractPeriod?.period,
                        coasId: data?.plan?.contractPeriod?.coasId,
                    },
                    // originalPrice: data?.originalPrice,
                    monthlyPrice: data?.plan?.contractPeriod?.discountedPrice,
                    discountedPrice: data?.discountedPrice,
                    orderTotalPrice: data?.amountTotal,
                    orderAmountTotal: data?.orderAmountTotal,
                    status: STATUS.find((status) => status._id === data?.status)?.name || "",
                    freeVas: data?.freeVas,
                    paidVas: data?.paidVas,
                    planTncs: data?.acceptedTncs?.planTncs,
                    overallTncs: data?.acceptedTncs?.overallTncs,
                    directSaleMyClubHKTTncs: data?.acceptedTncs?.directSaleMyClubHKT,
                    directSaleThisServicesTncs: data?.acceptedTncs?.directSaleThisServices,
                    savePersonalInformationTncs: data?.acceptedTncs?.savePersonalInformation,
                    quantity: data?.quantity,
                    promoCode: {
                        code: data?.promoCode,
                        discount: data?.promoDiscount,
                        name: data?.promoCodeName
                    }
                },
                clientInformation: {
                    title: data?.userInfo?.title,
                    fullName: data?.userInfo?.fullName,
                    idType: data?.userInfo?.idType,
                    idNumber: data?.userInfo?.idNumber,
                    //idApproved
                    companyName: data?.userInfo?.companyName,
                    companyPosition: data?.userInfo?.companyPosition,
                    email: data?.userInfo?.email,
                    mobileNumber: data?.userInfo?.mobileNumber,
                    address1: data?.userInfo?.mailingAddress?.[0],
                    address2: data?.userInfo?.mailingAddress?.[1],
                    mailingDistrict: data?.userInfo?.mailingDistrict,
                    // addressProof
                    contactUserInfo: {
                        title: data?.contactUserInfo?.title,
                        fullName: data?.contactUserInfo?.fullName,
                        email: data?.contactUserInfo?.email,
                        mobileNumber: data?.contactUserInfo?.mobileNumber,
                        idType: data?.contactUserInfo?.idType,
                        idNumber: data?.contactUserInfo?.idNumber,
                    },
                    deliveryInfo: data?.deliveryInfo
                },
                payment: {
                    transactionCreateDate: data?.paymentInfo?.transactionCreateDate,
                    transactionUpdateTime: data?.paymentInfo?.transactionUpdateTime,
                    transactionCaptureTime: data?.paymentInfo?.transactionCaptureTime,
                    ceksToken: data?.paymentInfo?.creditCard?.ceksToken,
                    approvalCode: data?.paymentInfo?.creditCard?.approvalCode,
                    cardType: data?.paymentInfo?.creditCard?.type,
                    expiryDate: `${data?.paymentInfo?.creditCard?.expiryDate?.month} / ${data?.paymentInfo?.creditCard?.expiryDate?.year}`,
                    holderName: data?.paymentInfo?.creditCard?.holderName,
                    oppTransactionNumber: data?.paymentInfo?.oppTransactionNumber,
                    oppTransactionStatus: data?.paymentInfo?.oppTransactionStatus,
                    oppTransactionResponseCode: data?.paymentInfo?.oppTransactionResponseCode,
                    oppTransactionPaymentResult: data?.paymentInfo?.oppTransactionPaymentResult,
                    oppTransactionApprovalStatus: data?.paymentInfo?.oppTransactionApprovalStatus,
                },
                delivery: {
                    deliveryInfo: {
                        deliveryDate: data?.deliveryInfo?.deliveryDate || "",
                        deliveryTime: data?.deliveryInfo?.deliveryTime || "",
                        pickup: data?.deliveryInfo?.pickup,
                        deliveryMethod: data?.deliveryInfo?.deliveryMethod,
                        deliveryAddress: data?.deliveryInfo?.deliveryAddress?.filter(n => n)?.toString() || "",
                        deliveryFee: data?.deliveryFee,
                        mobileNumber: data?.userInfo?.mobileNumber,
                    },
                    contactUserInfo: {
                        title: data?.contactUserInfo?.title,
                        fullName: data?.contactUserInfo?.fullName,
                        email: data?.userInfo?.email,
                        mobileNumber: data?.contactUserInfo?.mobileNumber,
                        idType: data?.contactUserInfo?.idType,
                        idNumber: data?.contactUserInfo?.idNumber,
                    },
                    maillingAddress: {
                        address1: data?.userInfo?.mailingAddress?.[0],
                        address2: data?.userInfo?.mailingAddress?.[1],
                        mailingDistrict: data?.userInfo?.mailingDistrict,
                    }
                },
                documentUpload: {
                    documents: Object.keys(data?.userDocuments || {}).map((key) => {
                        return {
                            ...data?.userDocuments[key]?.value,
                            documentType: key,
                            isUploadedRequired: data?.userDocuments[key]?.isUploadedRequired,
                        }
                    })
                },
                activityLog: {
                    remarks: data?.remarks
                },
                extraInfo: {
                    ...data?.extraInfo,
                    cordOrderStatus: data?.cordOrderStatus || "",
                    cordLastUpdateTime: data?.cordLastUpdateTime ? dayjs(data?.cordLastUpdateTime).format(constants.DATE_TIME_FORMAT) : "",
                },
                externalRemark: data?.externalRemark,
            }

            if (data?.plan?.newNumbers?.[0]) {
                payload.planInformation.plan.effectiveDateForDisplay = data?.plan?.newNumberEffectiveDate
            } else if (data?.plan?.mnpNumbers?.[0]?.effectiveDate) {
                payload.planInformation.plan.effectiveDateForDisplay = `${data?.plan?.mnpNumbers?.[0]?.effectiveDate} ${MNP_EFFECTIVE_TIME?.[data?.plan?.mnpNumbers?.[0]?.effectiveTime]}`
            }

            reset(payload)
        }
    }, [data])

    const mutation = useMutation({
        mutationFn: (payload) => updateOrder(orderItemId, payload),
        onSuccess: (data) => {
            // queryClient.invalidateQueries({ queryKey: ["planDetail", { planId }] })
            setSnackbar({
                type: 'success',
                message: 'Success'
            })
        },
        onError: (err) => onApiError(err),
    })

    const handleOnSubmit = (formValue) => {
        let payload = {};
        _.forEach(dirtyFields, (value, key) => {
            payload[key] = formValue[key]
        })
        // console.log("payload", payload)
        mutation.mutate(payload)
    }

    const handleViewDocument = async (item) => {
        if (!item) return;
        setIsFetchingDocument(true);
        const fileType = item?.filename.split('.').pop();
        try {
            const result = await viewDocumentById(orderItemId, item._id)
            viewUploadFileDialogRef.current.open({data: result, fileType});
        } catch (err) {
            setSnackbar({
                type: 'error',
                message: `Cannot view the document due to an unexpected error.`
            })
        } finally {
            setIsFetchingDocument(false);
        }
    }

    const activityLogMutation = useMutation({
        mutationFn: (payload) => addOrderItemRemark(orderItemId, payload),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ["orderDetail", { orderItemId }] })
            setSnackbar({
                type: 'success',
                message: 'Success'
            })
        },
        onError: onApiError,
    })

    const handleOnSubmitActivityLog = (index) => {
        try {
            let tmp = getValues(`${ActivityLogTab.id}.remarks`)?.[index];
            const payload = {
                item: tmp?.item,
                reason: tmp?.reason,
                remark: tmp?.remark
            }
            const result = activityLogSchema.validate(payload);
            if (result.error) {
                const displayErr = _.map(result?.error.details, (error) => error.message).join(" , ")
                setSnackbar({
                    type: 'error',
                    message: displayErr
                })
            } else {
                activityLogMutation.mutate(payload)
            }
        } catch (error) {
            console.log("handleOnSubmitRemarks-error", error)
        }
    }

    const ActivityLogColumns = () => {
        return [
            ...generalActivityLogColumns(control, `${ActivityLogTab.id}.remarks`),
            {
                dataKey: 'remove',
                label: 'Action',
                width: 100,
                style: {
                    justifyContent: 'center',
                },
                renderCell: (item, index) => {
                    if (!item.canRemove) return null
                    return (
                        <Grid container direction='row'>
                            <Grid item xs>
                                <DeleteIcon style={{ cursor: 'pointer' }} onClick={() => remove(index)} />
                            </Grid>
                            <Grid item xs>
                                <Button
                                    variant={"underline"} 
                                    onClick={() => handleOnSubmitActivityLog(index)}
                                    color="neon"
                                    disabled={!canModify}
                                >
                                    Add
                                </Button>
                            </Grid>
                        </Grid>

                    )
                }
            }
        ]
    }

    const Header = () => {
        return (
            <div style={{ flex: 1 }}>
                <Breadcrumbs aria-label="breadcrumb" separator=">">
                    <Link underline="hover" color="inherit" component={RouterLink} to="/order?type=orders">
                        <Typography variant="main" bold>All Orders</Typography>
                    </Link>
                    <Link underline="hover" color="inherit" component={RouterLink} to={`/order?type=orderItems&orderId=${data?.orderId}&orderNumber=${data?.orderNumber}`}>
                        <Typography bold variant="main">{data?.orderNumber}</Typography>
                    </Link>
                    <Typography bold variant="main">{data?.subOrderNumber}</Typography>
                </Breadcrumbs>
            </div>
        )
    }

    const renderForm = () => {
        const currentTab = TABS[tab]?.id
        const targetTab = TABS.find((tab) => tab.id === currentTab)
        switch (currentTab) {
            case "planInformation": {
                return (
                    <Form
                        fields={PlanInfoFields({
                            name: targetTab.id,
                            control,
                            errors: errors[targetTab.id],
                            readOnly: targetTab?.canEdit ? false : true,
                            mnpOperatorList: MNP_OPERATOR_LIST,
                        })}
                        p={`14px 0px`}
                    />
                )
            }

            case "payment": {
                return (
                    <Form
                        fields={PaymentFields({
                            name: targetTab.id,
                            control,
                            errors: errors[targetTab.id],
                            readOnly: targetTab?.canEdit ? false : true
                        })}
                        p={`14px 0px`}
                    />
                )
            }

            case "delivery": {
                return (
                    <Form
                        fields={DeliveryMethodFields({
                            name: targetTab.id,
                            control,
                            errors: errors[targetTab.id],
                            readOnly: targetTab?.canEdit ? false : true,
                            canViewPII: canViewPII,
                        })}
                        p={`14px 0px`}
                    />
                )
            }

            case "clientInformation": {
                return (
                    <Form
                        fields={ClientInfoFields({
                            name: targetTab.id,
                            control,
                            errors: errors[targetTab.id],
                            readOnly: targetTab?.canEdit ? false : true
                        })}
                        p={`14px 0px`}
                    />
                )
            }

            case "documentUpload": {
                return (
                    <Grid container direction='row'>
                        <DataTable
                            data={getValues(`${targetTab.id}.documents`) || []}
                            columns={DocUploadedColumns({control, onViewDocument: handleViewDocument})}
                            readOnly={targetTab?.canEdit ? false : true}
                        />
                    </Grid>
                )
            }

            case "externalRemarks": {
                return (
                    <Form
                        fields={ExternalRemarks({
                            name: targetTab.id,
                            control,
                        })}
                        p={`14px 0px`}
                    />
                )
            }

            case "activityLog": {
                return (
                    <DataTable
                        data={fields}
                        columns={ActivityLogColumns()}
                        renderCustomListItem={() => {
                            const onClickAdd = () => append({ ...DefRemark, updatedBy: user, _id: _.uniqueId('remark-'), canRemove: true })
                            return (
                                <TableRow>
                                    <TableCell sx={{ border: 'none' }}>
                                        <Button
                                            variant={"underline"}
                                            color="neon"
                                            onClick={onClickAdd}
                                            disabled={!canModify}
                                        >
                                            {"+ Add remarks"}
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            )
                        }}
                    />
                )
            }

            case "extraInfo": {
                return (
                    <Form
                        fields={ExtraInfoFields({
                            name: targetTab.id,
                            control,
                            errors: errors[targetTab.id],
                            readOnly: targetTab?.canEdit ? false : true
                        })}
                        p={`14px 0px`}

                    />
                )
            }

            default: {
                return null;
            }
        }
    }

    return (
        <PageWrapper
            loading={isLoading || activityLogMutation.isLoading || isFetchingDocument}
            renderHeader={Header}
            renderBackButton={() => <Button type='back' onClick={() => navigate(-1)} style={{ marginLeft: 10 }} />}
        >
            <Grid container direction='row' alignItems='center' justifyContent='flex-end'>
                <TableStatusFilter value={data?.status} options={STATUS} style={{ margin: `0px 10px` }} titleStyle={{ color: theme.palette.primary.main }} disabled />
                {/* <Typography variant="header3" sx={{ mr: 1 }}>Edited on {dayjs().format(constants.DATE_TIME_FORMAT)}</Typography> */}
                {/* <EditButton onClick={() => handleSubmit(handleOnSubmit)()}>Edit</EditButton> */}
                {TABS[tab]?.canEdit && <SaveButton disabled={!canModify} onClick={() => handleSubmit(handleOnSubmit)()}>Save</SaveButton>}
            </Grid>
            <Tab2
                tabs={TABS}
                selected={TABS[tab]?.id}
                onChange={(id, index) => setTab(index)}
            />
            <Content>
                {renderForm()}
                <ViewUploadFileDialog
                    ref={viewUploadFileDialogRef}
                />
            </Content>
        </PageWrapper>
    )
}

export default MobileDetail
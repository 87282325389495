import {
    useMutation,
    useQuery,
} from '@tanstack/react-query';
import Joi, { required } from 'joi';
import _ from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { createProduct, fetchProduct } from '../../apis/product';
import Summary from '../../components/product/Confirmation';
import ProductDetail from '../../components/product/ProductDetail';
import {
    Button,
    PageWrapper,
    PositionedMenu,
    Progressbar,
    PublishPeriodDialog,
    Typography,
    Form
} from '../../components/share';
import { snackbarContext } from '../../context/SnackbarProvider';
import { companiesContext } from '../../context/CompaniesProvider';
import { userContext } from '../../context/UserProvider';
import { vasContext } from '../../context/VasProvider';
import { tncContext } from '../../context/TncProvider';
import { getDirtyValues, removeEmptyElements, toIds, getPermission, toDisplayName } from '../../utils';
import {
    RecommendFields,
    Step1Fields,
    TncFields,
    VasFields
} from './formFields';
import constants from '../../constants'
import { ProductCategoryOptions } from '../../components/Drawer';

const SaveButton = styled(Button)`
    && {
        align-self: end; 
        margin: 10px 8px;
    }
`
const RowDirectionContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
`

const Content = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    overflow: auto;
`

export const schema = Joi.object({
    status: Joi.any(),
    publishStartDate: Joi.any(),
    publishEndDate: Joi.any(),
    step1: Joi.object({
        productCategory: Joi.string().empty("").required().custom(
            (value, helpers) => {
                if (!ProductCategoryOptions.map((o) => o.id).includes(value)) {
                    return helpers.error('any.invalid')
                }
                if (ProductCategoryOptions.filter((o) => o?.disabled).map((o) => o.id).includes(value)) {
                    return helpers.error('product.invalid')
                }
                return value
            }
        ),
        company: Joi.string().empty("").allow(null),
        promotionTitle: Joi.object({
            en: Joi.string().empty(""),
            zh: Joi.string().empty(""),
        }),
        flipCardLabel: Joi.object({
            en: Joi.string().empty(""),
            zh: Joi.string().empty(""),
        }),
        flipCardIconImage: Joi.object({
            fileKey: Joi.string().required(),
            previewUrl: Joi.string().allow('', null).optional(),
        }),
        brandName: Joi.object({
            en: Joi.string().required(),
            zh: Joi.string().required(),
        }),
        productName: Joi.object({
            en: Joi.string().required(),
            zh: Joi.string().required(),
        }),
        // internalItemCode: Joi.string().optional(),
        productSku: Joi.string().required(),
        markedPrice: Joi.number().required(),
        sellingPrice: Joi.number().allow(null),
        flipCardColors: Joi.array().items(),
    }),
    step2: Joi.object({
        variation: {
            variationProducts: Joi.array().items(
                Joi.object({
                    productName: Joi.object({
                        en: Joi.string(),
                        zh: Joi.string(),
                    }),
                    productSku: Joi.string().required(),
                    purchaseLimitPerUser: Joi.number().min(0).allow(null),
                    internalCode: Joi.string().required(),
                    markedPrice: Joi.number().required(),
                    sellingPrice: Joi.number().allow(null),
                    options: Joi.array().items(
                        Joi.object({
                            optionKey: Joi.string().empty(""),
                            valueKey: Joi.string().empty(""),
                        })
                    ),
                    imageUrls: Joi.any(),
                    images: Joi.array().min(1).required(),
                    productType: Joi.any(),
                    _id: Joi.any(),
                    id: Joi.any(),
                    en: Joi.any(),
                    zh: Joi.any(),
                })
            ),
            options: Joi.array().min(1)
                .ordered(Joi.object({
                    title: Joi.object({
                        en: Joi.string().required(),
                        zh: Joi.string().required(),
                    }),
                    options: Joi.array().min(1).items(Joi.object({
                        en: Joi.string().required(),
                        zh: Joi.string().required(),
                    }))
                }))
                .items(Joi.object({
                    title: Joi.object({
                        en: Joi.string().empty(""),
                        zh: Joi.string().empty(""),
                    }),
                    options: Joi.array().items(Joi.object({
                        en: Joi.string().empty(""),
                        zh: Joi.string().empty(""),
                    }))
                })),
        },
        productDetails: {
            description: Joi.object({
                en: Joi.string().empty(""),
                zh: Joi.string().empty(""),
            }),
        },
        productSpecifications: {
            specification: Joi.object({
                en: Joi.string().empty(""),
                zh: Joi.string().empty(""),
            }),
        },
        gallerySlider: {
            images: Joi.array().min(1).required(),
        },
        general: Joi.object({
            minQuantity: Joi.number().min(1).required(),
            maxQuantity: Joi.number().min(Joi.ref('minQuantity')).required().messages({'number.min': "Must be greater than Minimum No.", "any.ref": "Limit references Minimum No. which must be a number" }),
            isAllowedPickUp: Joi.boolean().valid(false).required().messages({'any.only': "Must be false" }),
            isAllowedDelivery: Joi.boolean().valid(true).required().messages({'any.only': "Must be true" }),
            haveFreeRemoval: Joi.string(),
        })
    }),
    step3: Joi.object({
        // tncs: Joi.array().required(),
        overallTncs: Joi.array().min(1).required(),
        // detailTncs: Joi.array().min(1).required(),
        detailTncs: Joi.array().optional(),
    }),
    step4: Joi.object({
        freeVas: Joi.array(),
        quantityOnFreeVas: Joi.number().integer().positive().allow(null, '').optional(),
        // bundleFreeVas: Joi.array().required(),
        paidVas: Joi.array(),
    }),
    step5: {
        recommend: Joi.array()
    }
}).messages({
    "any.required": "Required",
    "number.base": "Must be a number",
    "object.base": "Required",
    "string.empty": "Required",
    "array.min": "Must contain at least {#limit} items",
    'number.min': 'Must be greater than or equal to {#limit}',
    "number.positive": "Must be a positive number. If there is no value, please leave it blank.",
    "number.integer": "Must be an integer",
    "any.invalid": "Invalid",
    "product.invalid": "This product category is already abondoned. Please select another one.",
});

export const Tabs = [
    {
        name: "Flip Card",
        id: "step1"
    },
    {
        name: "Product Details",
        id: "step2"
    },
    {
        name: "T&C And Remark",
        id: "step3"
    },
    {
        name: "VAS",
        id: "step4"
    },
    {
        name: "Recommend",
        id: "recommend"
    },
    // {
    //     name: "Control",
    //     id: "control"
    // }
];

export const DefOptionFields = {
    en: "",
    zh: ""
}

export const DefColorFields = [
    "#ffffff"
]

export const DefVariationOptionsFields = [
    {
        title: {
            en: "",
            zh: ""
        },
        options: []
    },
    {
        title: {
            en: "",
            zh: ""
        },
        options: []
    }
]

function ProductAddPAge(props) {
    const navigate = useNavigate();

    const [step, setStep] = useState(0);
    const [publishDialog, setPublishDialog] = useState({ open: false })
    const [showSummary, setShowSummary] = useState(false)
    const [snackbar, setSnackbar] = useContext(snackbarContext);
    const { VIPcompanies } = useContext(companiesContext);
    const [vasList] = useContext(vasContext);
    const [tncList] = useContext(tncContext);
    let [searchParams, setSearchParams] = useSearchParams();
    const [user, setUser] = useContext(userContext);

    const { canView, canModify } = getPermission(user?.permissions?.product)

    let { productType } = useParams();
    const productId = searchParams.get('productId');

    const { control, handleSubmit, watch, formState: { errors, dirtyFields }, reset, setValue, getValues, setError, clearErrors } = useForm({
        // resolver: joiResolver(schema),
        defaultValues: {
            step1: {
                flipCardColors: DefColorFields,
                flipCardIconImage: {}
            },
            step2: {
                variation: {
                    options: DefVariationOptionsFields
                },
                productDetails: {
                    description: DefOptionFields
                },
                productSpecifications: {
                    specification: DefOptionFields
                },
                gallerySlider: {
                    images: []
                },
                general: {
                    isAllowedPickUp: false,
                    isAllowedDelivery: true,
                    minQuantity: 1,
                    maxQuantity: 1
                }
            },
            step3: {
            },
            step4: {},
            step5: {
                recommend: Array.from({ length: 3 }, (v, i) => `${i}`)
            }
        }
    });

    const { data, error, isError, isLoading } = useQuery({
        queryKey: ["productDetail", { productId }],
        queryFn: async () => await fetchProduct(productId),
        enabled: Boolean(productId),
        onError: (error) => {
            setSnackbar({
                type: 'error',
                message: `${error.code} ${error.errorMessage ? `- ${error.errorMessage?.en}` : ""}`
            })
        }
    });

    useEffect(() => {
        if (productId && data) {
            const defRecommend = Array.from({ length: 3 }, (v, i) => `${i}`)
            const recommendPlanList = _.map(data?.recommendPlans, (item) => ({ category: 'plan', ...item }))
            const recommendProductList = _.map(data?.recommendProducts, (item) => ({ category: 'product', ...item }))
            const recommendList = _.concat(recommendPlanList, recommendProductList, defRecommend).slice(0, 3);
            const newVariationProducts =  data.productOptions?.length > 0 ?
            _.map(data?.variationProducts, (vp) => ({
                ...vp,
                images: _.zip(vp.images, vp.imageUrls).map(o => {
                    return ({
                        fileKey: o[0],
                        previewUrl: o[1],
                        url: o[1], 
                        xs: 1, 
                        id: o[0]
                    })
                })
            }))
            : []
            reset({
                step1: {
                    productCategory: data?.productCategory,
                    company: data?.company,
                    promotionTitle: data.promotionTitle || {},
                    flipCardLabel: data?.flipCardLabel || {},
                    flipCardIconImage: {
                        fileKey: data?.flipCardIconImage,
                        previewUrl: data?.flipCardIconImageUrl
                    },
                    brandName: data?.brandName || {},
                    productName: data?.productName || {},
                    //internalItemCode
                    productSku: data.productSku,
                    sellingPrice: data?.sellingPrice || null,
                    markedPrice: data?.markedPrice || null,
                    flipCardColors: data?.flipCardColors || [],
                },
                step2: {
                    variation: {
                        options: data?.productOptions && data.productOptions?.length > 0 
                            ? Array(2).fill().map((_, i) => {
                                if (!data?.productOptions?.[i]) return DefVariationOptionsFields?.[0];
                                
                                const { option={}, values=[] } = data?.productOptions?.[i];

                                return {
                                    title: option?.label,
                                    options: values?.length > 0 ? values.map((o) => o?.label) : [],
                                }
                            })
                            : DefVariationOptionsFields,
                        variationProducts: newVariationProducts,
                    },
                    productDetails: data?.description ? { description: data.description } : { description: DefOptionFields },
                    productSpecifications: data?.specification ? { specification: data.specification } : { specification: DefOptionFields },
                    gallerySlider: data?.images ?
                        {
                            images: _.map(data?.images, (item, i) => {
                                return {
                                    fileKey: item,
                                    previewUrl: data?.imageUrls?.[i]
                                }
                            })
                        } :
                        { images: [] },
                    general: {
                        minQuantity: data?.minQuantity,
                        maxQuantity: data?.maxQuantity,
                        isAllowedPickUp: data?.isAllowedPickUp,
                        isAllowedDelivery: data?.isAllowedDelivery,
                        haveFreeRemoval: data?.haveFreeRemoval === undefined ? undefined : data.haveFreeRemoval ? 'enable' : 'disable'
                    }
                },
                step3: {
                    detailTncs: data?.detailTncs,
                    overallTncs: data?.overallTncs,
                },
                step4: {
                    quantityOnFreeVas: data?.quantityOnFreeVas,
                    freeVas: data?.freeVas,
                    paidVas: data?.paidVas,
                },
                step5: {
                    recommend: recommendList
                }
            })
        }
    }, [data, productId])

    const mutation = useMutation({
        mutationFn: (payload) => createProduct(payload),
        onSuccess: (data) => {
            navigate(`/product?type=${data?.productCategory}`);
            setSnackbar({
                type: 'success',
                message: 'Success'
            })
        },
        onError: (error) => {
            console.log("error", error)
            setSnackbar({
                type: 'error',
                message: `${error.code} ${error.errorMessage ? `- ${error.errorMessage?.en}` : ""}`
            })
        },
    })

    const validateForm = () => {
        clearErrors();
        let values = _.cloneDeep(getValues());
        console.log("values", values)
        if (values?.step1?.flipCardIconImage?.fileKey) {
            values.step1.flipCardIconImage = {
                fileKey: values.step1.flipCardIconImage.fileKey
            }
        }
        const result = schema.validate(values, { abortEarly: false });
        if (result.error) {
            _.forEach(result.error.details, (item) => {
                setError(item.context.label, { message: item.message })
            })
            
            setValue('status', "draft", { shouldDirty: true })
            setValue('publishStartDate', undefined, { shouldDirty: true })
            setValue('publishEndDate', undefined, { shouldDirty: true })

            setShowSummary(false)
            setSnackbar({
                type: 'error',
                message: constants.INVALID_INPUT_ERR_MSG
            })
        } else {
            handleSubmit(handleOnSubmit)()
        }
    }

    const handleOnSubmit = (formValue) => {
        const dirty = productId ? formValue : getDirtyValues(dirtyFields, formValue);
        let payload = {
            status: "draft",
            productCategory: formValue?.step1.productCategory,
            flipCardColors: formValue.step1.flipCardColors,
            minQuantity: formValue.step2.general.minQuantity,
            maxQuantity: formValue.step2.general.maxQuantity,
            isAllowedPickUp: false, //TODO:
            isAllowedDelivery: true  //TODO:
        }
        for (const property in dirty) {
            if (property === "publishStartDate" || property === "publishEndDate") {
                if (dirty[property]) {
                    payload[property] = dirty[property]
                    payload['status'] = 'active'
                }
            } else if (property === 'step2') {
                const step2Values = dirty[property]
                for (const stepProperty in step2Values) {
                    payload = { ...payload, ...step2Values[stepProperty] }
                }
            } else if (typeof dirty[property] !== 'object') {
                payload = { ...payload, [property]: dirty[property] };
            } else {
                payload = { ...payload, ...dirty[property] };
            }
        }
        _.forEach(payload, (item, key) => {
            if (key === 'recommend') {
                let tmpRecommend = [...payload.recommend];
                payload['recommendProducts'] = _.chain(tmpRecommend).filter((o) => o.category === "product" && o._id).map((v) => v._id).value();
                payload['recommendPlans'] = _.chain(tmpRecommend).filter((o) => o.category === "plan" && o._id).map((v) => v._id).value();
                delete payload.recommend
            } else if (key === "flipCardIconImage") {
                payload['flipCardIconImage'] = item?.fileKey
            } else if (key === "sellingPrice") {
                payload['sellingPrice'] = payload.sellingPrice === "" || isNaN(payload.sellingPrice) ? undefined : Number(payload.sellingPrice)
            } else if (key === "markedPrice" ) {
                payload['markedPrice'] = payload.markedPrice === "" || isNaN(payload.markedPrice) ? undefined : Number(payload.markedPrice)
            } else if (key === "variationProducts") {
                payload['variationProducts'] = _.map(item, (item2) => {
                    return {
                        productName: {
                            en: item2?.en,
                            zh: item2?.zh
                        },
                        internalCode: item2.internalCode,
                        markedPrice: item2?.markedPrice === "" || isNaN(item2?.markedPrice) ? null : Number(item2.markedPrice),
                        sellingPrice: item2?.sellingPrice === "" || isNaN(item2?.sellingPrice) ? null : Number(item2.sellingPrice),
                        options: item2.options,
                        images: _.map(item2.images, (v) => v.fileKey),
                        productSku: item2.productSku,
                        purchaseLimitPerUser: _.isEmpty(item2?.purchaseLimitPerUser) ? null : Number(item2.purchaseLimitPerUser),
                    }
                })
            } else if (key === 'options') {
                let tmp = []
                _.forEach(item, (v, i) => {
                    if (v?.title?.en) {
                        tmp.push({
                            optionKey: v?.title?.en,
                            label: v.title,
                            optionIndex: i,
                            optionValues: _.map(v.options, (o) => {
                                return {
                                    valueKey: o?.en,
                                    label: o
                                }
                            })
                        })
                    }
                })
                payload['productOptions'] = tmp
                delete payload.options
            } else if (key === 'images') {
                payload['images'] = _.map(item, (v) => v.fileKey)
            } else if (key === 'haveFreeRemoval') {
                payload['haveFreeRemoval'] = item ? item === 'disable' ? false : true : undefined
            } else if (key === 'detailTncs' || key === "overallTncs" || key === "freeVas" || key === "paidVas" ) {
                payload[key] = toIds(item)
            } else if (key === "quantityOnFreeVas" ) {
                payload['quantityOnFreeVas'] = payload.quantityOnFreeVas === "" || isNaN(payload.quantityOnFreeVas) ? null : Number(payload.quantityOnFreeVas)
            }
        })
        payload = removeEmptyElements(payload)
        console.log("payload", payload)
        mutation.mutate(payload);
    }

    const renderForm = () => {
        switch (step) {
            case 0: {
                return (
                    <Content>
                        <Form fields={Step1Fields({ name: "step1", control, errors: errors.step1, companies: VIPcompanies })} p={`14px 0px`} />
                    </Content>
                )
            }

            case 1: {
                return (
                    <Content>
                        <ProductDetail
                            control={control}
                            errors={errors.step2}
                            name="step2"
                            setValue={setValue}
                            getValues={getValues}
                        />
                    </Content>
                )
            }

            case 2: {
                return (
                    <Content>
                        <Form fields={TncFields({ control, errors: errors.step3, name: "step3", tncList })} p={`14px 0px`} />
                    </Content>
                )
            }

            case 3: {
                return (
                    <Content>
                        <Form fields={VasFields({ control, errors: errors.step4, name: "step4", vasList })} p={`14px 0px`} />
                    </Content>
                )
            }

            case 4: {
                return (
                    <Content>
                        <Form fields={RecommendFields({ control, errors, name: "step5" })} p={`14px 0px`} />
                    </Content>
                )
            }

            // case 5: {
            //     return (
            //         <Content>
            //             <ControlComponent control={control} reset={reset} getValues={getValues} />
            //         </Content>
            //     )
            // }

            default: {
                return null
            }
        }
    }


    const onSelectPublishType = (type) => {
        if (type.id === 'publish-now') {
            setValue('status', 'active', { shouldDirty: true })
            setValue('publishStartDate', new Date().toISOString(), { shouldDirty: true })
            validateForm()
        } else {
            setPublishDialog({ open: true })
        }
    }

    const isLastStep = step === (Tabs.length - 1)

    const updateStep = (type) => {
        if (showSummary && type === "deduct") {
            if (type === "deduct") setShowSummary(false)
            return
        }
        if (type === 'add' && canModify) {
            if (isLastStep) {
                // handleSubmit(handleOnSubmit)()
                setShowSummary(true)
            } else {
                if (step < (Tabs.length - 1)) setStep(step + 1)
            }
        } else {
            if (step >= 1) setStep(step - 1)
        }
    }

    console.log("errors", errors)

    const Header = () => {
        return (
            <>
                <Typography bold style={{ flex: 1 }}>{`Product > ${toDisplayName(productType)} > Add New`}</Typography>
            </>
        )
    }

    const CardHeader = () => {
        return (
            <RowDirectionContainer>
                {/* <SaveButton type='outlined' onClick={() => setShowSummary(!showSummary)}>Preview</SaveButton> */}
                <SaveButton variant='outlined' disabled={!step} onClick={() => updateStep('deduct')}>Previous</SaveButton>
                <SaveButton
                    variant='outlined'
                    onClick={() => {
                        clearErrors();
                        handleSubmit(handleOnSubmit)()
                    }}
                    disabled={!canModify}
                >
                    Save As Draft
                </SaveButton>
                {
                    showSummary ?
                        <PositionedMenu
                            label="Publish"
                            isLoading={mutation.isLoading}
                            options={[
                                {
                                    label: "Publish Now",
                                    id: "publish-now"
                                },
                                {
                                    label: "Select Publish Period",
                                    id: 'select-publish-period'
                                }
                            ]}
                            onSelect={onSelectPublishType}
                        /> :
                        <SaveButton onClick={() => updateStep('add')}>{isLastStep ? 'Preview' : 'Next'}</SaveButton>
                }
            </RowDirectionContainer>
        )
    }

    return (
        <PageWrapper
            loading={productId && isLoading}
            renderHeader={Header}
            renderBackButton={() => <Button type='back' onClick={() => navigate(-1)} style={{ marginLeft: 10 }} />}
            renderCardHeader={CardHeader}
        >
            {
                showSummary ?
                    <Summary control={control} reset={reset} getValues={getValues} />
                    :
                    <>
                        <Progressbar step={step} data={Tabs} error={Object.keys(errors)} />
                        {renderForm()}
                    </>
            }
            <PublishPeriodDialog
                open={publishDialog.open}
                handleClose={() => setPublishDialog({ open: false })}
                onSubmit={() => validateForm()}
                control={control}
            />
        </PageWrapper>
    )
}

export default ProductAddPAge
import {
    useMutation,
    useQuery,
    useQueryClient
} from '@tanstack/react-query';
import _ from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { fetchProduct, updateProduct } from '../../apis/product';
import Summary from '../../components/product/Confirmation';
import ControlComponent from '../../components/product/Control';
import ProductDetail from '../../components/product/ProductDetail';
import {
    Button,
    PageWrapper,
    PositionedMenu,
    Progressbar,
    PublishPeriodDialog,
    Typography,
    Form
} from '../../components/share';
import { snackbarContext } from '../../context/SnackbarProvider';
import { companiesContext } from '../../context/CompaniesProvider';
import { vasContext } from '../../context/VasProvider';
import { tncContext } from '../../context/TncProvider';
import { getDirtyValues, isDirtyArray, toIds, getPermission, toDisplayName } from '../../utils';
import { DefOptionFields, DefVariationOptionsFields, Tabs, schema } from './add';
import {
    RecommendFields,
    Step1Fields,
    TncFields,
    VasFields
} from './formFields';
import { userContext } from '../../context/UserProvider';
import constants from '../../constants'

const SaveButton = styled(Button)`
    && {
        align-self: end; 
        margin: 10px 8px;
        height: 30px;
        padding: 0px 20px;
    }
`
const RowDirectionContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: ${props => props.showConfirmation ? "space-between" : "flex-end"};
`

const Content = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    overflow: auto;
`

const margin = 10;

const getResetData = ({ data }) => {
    const defRecommend = Array.from({ length: 3 }, (v, i) => `${i}`)
    const recommendPlanList = _.map(data?.recommendPlans, (item) => ({ category: 'plan', ...item }))
    const recommendProductList = _.map(data?.recommendProducts, (item) => ({ category: 'product', ...item }))
    const recommendList = _.concat(recommendPlanList, recommendProductList, defRecommend).slice(0, 3);
    const newVariationProducts = data.productOptions?.length > 0 ?
        _.map(data?.variationProducts, (vp) => ({
            ...vp,
            images: _.zip(vp.images, vp.imageUrls).map(o => {
                return ({
                    fileKey: o[0],
                    previewUrl: o[1],
                    url: o[1],
                    xs: 1,
                    id: o[0]
                })
            })
        }))
        : []
    return {
        step1: {
            productCategory: data?.productCategory,
            company: data?.company,
            promotionTitle: data.promotionTitle || {},
            flipCardLabel: data?.flipCardLabel || {},
            flipCardIconImage: {
                fileKey: data?.flipCardIconImage,
                previewUrl: data?.flipCardIconImageUrl
            },
            brandName: data?.brandName || {},
            productName: data?.productName || {},
            //internalItemCode
            productSku: data.productSku,
            sellingPrice: isNaN(data.sellingPrice) ? null : Number(data.sellingPrice),
            markedPrice: isNaN(data.markedPrice) ? null : Number(data.markedPrice),
            flipCardColors: data?.flipCardColors || [],
        },
        step2: {
            variation: {
                options: data?.productOptions && data.productOptions.length > 0 ? _.map(data?.productOptions, (item) => {
                    return {
                        title: item.option.label,
                        options: _.map(item?.values, (opt) => opt.label)
                    }
                }) : DefVariationOptionsFields,
                variationProducts: newVariationProducts
            },
            productDetails: data?.description ? { description: data.description } : { description: DefOptionFields },
            productSpecifications: data?.specification ? { specification: data.specification } : { specification: DefOptionFields },
            gallerySlider: data?.images ? {
                images: _.map(data.imageUrls, (v, i) => ({
                    fileKey: data.images[i],
                    previewUrl: v,
                    url: v,
                    id: data.images[i],
                    xs: 1,
                }))
            }
                :
                { images: [] },
            general: {
                minQuantity: data?.minQuantity,
                maxQuantity: data?.maxQuantity,
                isAllowedPickUp: data?.isAllowedPickUp,
                isAllowedDelivery: data?.isAllowedDelivery,
                haveFreeRemoval: data?.haveFreeRemoval === undefined ? undefined : data.haveFreeRemoval ? 'enable' : 'disable'
            }
        },
        step3: {
            detailTncs: data?.detailTncs,
            overallTncs: data?.overallTncs,
        },
        step4: {
            quantityOnFreeVas: data?.quantityOnFreeVas,
            freeVas: data?.freeVas,
            paidVas: data?.paidVas,
        },
        step5: {
            recommend: recommendList
        }
    }
}



function ProductDetailPage(props) {
    const navigate = useNavigate();

    const [step, setStep] = useState(0);
    const [showSummary, setShowSummary] = useState(false)
    const [snackbar, setSnackbar] = useContext(snackbarContext);
    const { VIPcompanies } = useContext(companiesContext);
    const [vasList] = useContext(vasContext);
    const [tncList] = useContext(tncContext);
    const [publishDialog, setPublishDialog] = useState({ open: false })
    const [user, setUser] = useContext(userContext);

    const { canView, canModify } = getPermission(user?.permissions?.product)

    let { productId } = useParams();

    const { control, handleSubmit, watch, formState: { errors, dirtyFields }, reset, getValues, setValue, setError, clearErrors } = useForm({
        // resolver: joiResolver(schema),
    });

    const { data, error, isError, isLoading } = useQuery({
        queryKey: ["/product", { productId }],
        queryFn: async () => await fetchProduct(productId),
        onError: (error) => {
            setSnackbar({
                type: 'error',
                message: `${error.code} ${error.errorMessage ? `- ${error.errorMessage?.en}` : ""}`
            })
        }
    });

    useEffect(() => {
        if (data) {
            reset(getResetData({ data }))
        }
    }, [data, productId])

    const queryClient = useQueryClient()
    const mutation = useMutation({
        mutationFn: (payload) => updateProduct(productId, payload),
        onSuccess: (data) => {
            setSnackbar({
                type: 'success',
                message: 'Success'
            })
            // reset(getResetData({ data }))
            queryClient.invalidateQueries({ queryKey: ["/product", { productId }] })
            if (showSummary) {
                navigate(`/product?type=${data?.productCategory}`);
            }
        },
        onError: (error) => {
            setSnackbar({
                type: 'error',
                message: `${error.code} ${error.errorMessage ? `- ${error.errorMessage?.en}` : ""}`
            })
        }
    })

    const validateForm = () => {
        clearErrors();
        let values = _.cloneDeep(getValues());
        if (values?.step1?.flipCardIconImage?.fileKey) {
            values.step1.flipCardIconImage = {
                fileKey: values.step1.flipCardIconImage.fileKey,
                previewUrl: values.step1.flipCardIconImage.previewUrl,
            }
        }
        const result = schema.validate(values, { abortEarly: false });
        if (result.error) {
            _.forEach(result.error.details, (item) => {
                setError(item.context.label, { message: item.message })
            })

            setValue('status', "draft", { shouldDirty: true })
            setValue('publishStartDate', undefined, { shouldDirty: true })
            setValue('publishEndDate', undefined, { shouldDirty: true })

            setShowSummary(false)
            setSnackbar({
                type: 'error',
                message: constants.INVALID_INPUT_ERR_MSG
            })
        } else {
            handleSubmit(handleOnSubmit)()
        }
    }

    const handleOnSubmit = (formValue) => {
        let simplifyFormValue = {}
        for (const property in formValue) {
            if (property === "publishStartDate" || property === "publishEndDate" || property === "status") {
                simplifyFormValue[property] = formValue[property]
            } else if (property === 'step2') {
                const step2Values = formValue[property]
                for (const stepProperty in step2Values) {
                    simplifyFormValue = { ...simplifyFormValue, ...step2Values[stepProperty] }
                }
            } else {
                simplifyFormValue = { ...simplifyFormValue, ...formValue[property] };
            }
        }

        let simplifyDirtyFields = {}
        for (const property in dirtyFields) {
            if (property === 'step2') {
                const step2 = dirtyFields[property]
                for (const stepProperty in step2) {
                    simplifyDirtyFields = { ...simplifyDirtyFields, ...step2[stepProperty] }
                    //simplifyDirtyFields[stepProperty] = step2[stepProperty]
                }
            } else {
                if (typeof dirtyFields[property] !== "object") {
                    simplifyDirtyFields[property] = dirtyFields[property]
                } else {
                    simplifyDirtyFields = { ...simplifyDirtyFields, ...dirtyFields[property] }
                }
            }
        }

        let newDirtyFields = {}
        _.forEach(simplifyDirtyFields, (dirtyValue, dirtyKey) => {
            if (typeof dirtyValue === "object") {
                const check = isDirtyArray(dirtyValue)
                if (check === true) newDirtyFields[dirtyKey] = true
            } else if (dirtyValue === true) {
                newDirtyFields[dirtyKey] = true
            }
        })
        const dirty = getDirtyValues(newDirtyFields, simplifyFormValue);

        let payload = { status: "draft", ...dirty }
        if (formValue?.publishStartDate) {
            payload['publishStartDate'] = formValue?.publishStartDate
            payload['status'] = 'active'
        }
        if (formValue?.publishEndDate) {
            payload['publishEndDate'] = formValue?.publishEndDate
            payload['status'] = 'active'
        }

        _.forEach(payload, (item, key) => {
            if (key === 'recommend') {
                const tmpRecommend = [...payload.recommend];
                payload['recommendProducts'] = _.chain(tmpRecommend).filter((o) => o.category === "product" && o._id).map((v) => v._id).value();
                payload['recommendPlans'] = _.chain(tmpRecommend).filter((o) => o.category === "plan" && o._id).map((v) => v._id).value();
                delete payload.recommend
            } else if (key === "sellingPrice") {
                payload['sellingPrice'] = payload.sellingPrice === "" || isNaN(payload.sellingPrice) ? null : Number(payload.sellingPrice)
            } else if (key === "markedPrice" ) {
                payload['markedPrice'] = payload.markedPrice === "" || isNaN(payload.markedPrice) ? null : Number(payload.markedPrice)
            }  else if (key === "flipCardIconImage" && item?.fileKey) {
                payload['flipCardIconImage'] = item.fileKey
            } else if (key === "cardFront" || key === "cardBack" || key === "planDetailsInclude") {
                let tmpArray = [...item];
                payload[key] = _.map(tmpArray, (o) => ({ ...o, image: o?.image?.fileKey || "" }))
            } else if (key === "variationProducts") {
                payload['newVariationProducts'] = _.map(item, (item2) => {
                    return {
                        productName: {
                            en: item2?.en,
                            zh: item2?.zh
                        },
                        internalCode: item2.internalCode,
                        markedPrice: item2?.markedPrice === "" || isNaN(item2?.markedPrice) ? null : Number(item2.markedPrice),
                        sellingPrice: item2?.sellingPrice === "" || isNaN(item2?.sellingPrice) ? null : Number(item2.sellingPrice),
                        options: item2.options,
                        images: _.map(item2.images, (v) => v.fileKey),
                        productSku: item2.productSku,
                        purchaseLimitPerUser: _.isEmpty(item2?.purchaseLimitPerUser) ? null : Number(item2.purchaseLimitPerUser),
                    }
                })
                delete payload.variationProducts
            } else if (key === 'options') {
                let tmp = []
                _.forEach(item, (v, i) => {
                    if (v?.title?.en) {
                        tmp.push({
                            optionKey: v?.title?.en,
                            label: v.title,
                            optionIndex: i,
                            optionValues: _.map(v.options, (o) => {
                                return {
                                    valueKey: o?.en,
                                    label: o
                                }
                            })
                        })
                    }
                })
                payload['newProductOptions'] = tmp
                delete payload.options
            } else if (key === 'images') {
                payload['newImages'] = _.map(item, (v) => v.fileKey)
                delete payload.images
            } else if (key === 'haveFreeRemoval') {
                payload['haveFreeRemoval'] = item ? item === 'disable' ? false : true : undefined
            } else if (key === 'detailTncs' || key === "overallTncs" || key === "freeVas" || key === "paidVas") {
                payload[key] = toIds(item)
            } else if (key === "quantityOnFreeVas" ) {
                payload['quantityOnFreeVas'] = payload.quantityOnFreeVas === "" || isNaN(payload.quantityOnFreeVas) ? null : Number(payload.quantityOnFreeVas)
            }
        })
        // payload = removeEmptyElements(payload)
        console.log("payload", payload)
        mutation.mutate(payload)
    }

    const Header = () => {
        return (
            <>
                <Typography bold style={{ flex: 1 }}>{`Product > ${toDisplayName(data?.productCategory)} > ${productId}`}</Typography>
            </>
        )
    }

    const canEdit = data?.status === 'draft' ? true : false

    const renderForm = () => {
        switch (step) {
            case 0: {
                return (
                    <Content key={step}>
                        <Form fields={Step1Fields({ name: "step1", control, errors: errors.step1, readOnly: !canModify || !canEdit, companies: VIPcompanies })} p={`14px 0px`} />
                    </Content>
                )
            }

            case 1: {
                return (
                    <Content key={step}>
                        <ProductDetail
                            control={control}
                            errors={errors.step2}
                            name="step2"
                            readOnly={!canModify || !canEdit}
                            getValues={getValues}
                            setValue={setValue}
                        />
                    </Content>
                )
            }

            case 2: {
                return (
                    <Content key={step}>
                        <Form fields={TncFields({ control, errors: errors.step3, name: "step3", readOnly: !canModify || !canEdit, tncList })} p={`14px 0px`} />
                    </Content>
                )
            }

            case 3: {
                return (
                    <Content key={step}>
                        <Form fields={VasFields({ control, errors: errors.step4, name: "step4", readOnly: !canModify || !canEdit, vasList })} p={`14px 0px`} />
                    </Content>
                )
            }

            case 4: {
                return (
                    <Content key={step}>
                        <Form fields={RecommendFields({ control, errors, name: "step5", readOnly: !canModify || !canEdit })} p={`14px 0px`} />
                    </Content>
                )
            }

            case 5: {
                return (
                    <Content key={step}>
                        <ControlComponent control={control} reset={reset} getValues={getValues} />
                    </Content>
                )
            }

            default: {
                return null
            }
        }
    }

    const isLastStep = step === (Tabs.length - 1)

    const updateStep = (type) => {
        if (showSummary && type === "deduct") {
            if (type === "deduct") setShowSummary(false)
            return
        }
        if (type === 'add') {
            if (isLastStep && canEdit && canModify) {
                // handleSubmit(handleOnSubmit)()
                setShowSummary(true)
            } else {
                if (step < (Tabs.length - 1)) setStep(step + 1)
            }
        } else {
            if (step >= 1) setStep(step - 1)
        }
    }

    const CardHeader = () => {
        return (
            <RowDirectionContainer>
                <SaveButton variant='outlined' disabled={!step} onClick={() => updateStep('deduct')}>Previous</SaveButton>
                <SaveButton
                    variant='outlined'
                    disabled={!canEdit || !canModify}
                    loading={isLoading || mutation.isLoading}
                    onClick={() => {
                        clearErrors();
                        handleSubmit(handleOnSubmit)()
                    }}
                >
                    Save As Draft
                </SaveButton>
                {
                    showSummary ?
                        <PositionedMenu
                            label="Publish"
                            isLoading={isLoading || mutation.isLoading}
                            options={[
                                {
                                    label: "Publish Now",
                                    id: "publish-now"
                                },
                                {
                                    label: "Select Publish Period",
                                    id: 'select-publish-period'
                                }
                            ]}
                            onSelect={(type) => {
                                if (type.id === "publish-now") {
                                    setValue('status', 'active', { shouldDirty: true });
                                    setValue('publishStartDate', new Date().toISOString(), { shouldDirty: true })
                                    // handleSubmit(handleOnSubmit)();
                                    validateForm()
                                } else {
                                    setPublishDialog({ open: true })
                                }
                            }}
                        /> :
                        <SaveButton disabled={isLastStep && (!canEdit || !canModify)} onClick={() => updateStep('add')}>{isLastStep && canEdit ? 'Preview' : 'Next'}</SaveButton>
                }
            </RowDirectionContainer>
        )
    }

    console.log("errors", errors)

    return (
        <PageWrapper
            loading={isLoading || mutation.isLoading}
            renderHeader={Header}
            renderBackButton={() => <Button type='back' onClick={() => navigate(-1)} style={{ marginLeft: 10 }} />}
            renderCardHeader={CardHeader}
        >
            {
                showSummary ?
                    <Summary control={control} reset={reset} getValues={getValues} />
                    :
                    <>
                        <Progressbar step={step} data={Tabs} error={Object.keys(errors)} />
                        {renderForm()}
                    </>
            }
            <PublishPeriodDialog
                open={publishDialog.open}
                handleClose={() => setPublishDialog({ open: false })}
                onSubmit={() => validateForm()}
                control={control}
            />
        </PageWrapper>
    )
}

export default ProductDetailPage
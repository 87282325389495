import { joiResolver } from '@hookform/resolvers/joi';
import { Grid, Box } from '@mui/material';
import {
    useMutation,
    useQuery,
} from '@tanstack/react-query';
import { useContext, useEffect } from 'react';
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { fetchBanner, updateBanner } from '../../apis/banner';
import {
    GreyDivider
} from '../../components';
import ImageUpload from '../../components/banner/ImageUpload';
import {
    Button,
    ComponentContainer,
    PageWrapper,
    Select,
    TextField,
    Typography
} from '../../components/share';
import { snackbarContext } from '../../context/SnackbarProvider';
import { companiesContext } from '../../context/CompaniesProvider';
import { schema } from './add';
import { TabList } from './index';
import { userContext } from '../../context/UserProvider';
import { getPermission } from '../../utils';
import _ from 'lodash'
import constants from '../../constants';
import { styled as styledMui } from '@mui/material/styles';

const Content = styledMui('div')(
    ({ theme }) => `
    display: flex;
    flex-direction: column;
    margin: 20px;
    height: 100%;
    background: ${theme.palette.mainColor.light};
    border-radius: 5px;
    flex-wrap: nowrap;
    padding: 20px 40px;
    overflow: auto;
  `,
);

const FormContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: #fff;
    border-radius: 5px;
    padding: 20px;
    // height: 100%;
    
    > * {
        margin-bottom: 20px;
    }
`

const Language = [
    {
        id: "en",
        label: "English"
    },
    {
        id: "zh",
        label: "Chinese"
    }
]

function BannerDetailPage() {

    const navigate = useNavigate();
    const [snackbar, setSnackbar] = useContext(snackbarContext);
    const [user, setUser] = useContext(userContext);
    const { companies } = useContext(companiesContext);
    let { bannerId } = useParams();
    const { canView, canModify } = getPermission(user?.permissions?.banner)

    const { data, error, isError, isLoading } = useQuery({
        queryKey: ["bannerDetail", { bannerId }],
        queryFn: async () => await fetchBanner(bannerId),
        onError: (error) => {
            setSnackbar({
                type: 'error',
                message: `${error.code} ${error.errorMessage ? `- ${error.errorMessage?.en}` : ""}`
            })
        }
    });

    const { control, handleSubmit, watch, formState: { errors }, reset, setValue, getValues } = useForm({
        resolver: joiResolver(schema),
    });

    useEffect(() => {
        if (!_.isEmpty(errors)) {
            setSnackbar({
                type: "error",
                message: constants.INVALID_INPUT_ERR_MSG
            })
        }
    }, [errors])

    useEffect(() => {
        if (data) {
            reset({
                // allowedCompany: data?.allowedCompany,
                allowedCompany: data?.allowedCompany ? _.map(data?.allowedCompany, (item) => item.companyId) : [], //TODO:
                // allowedCompany: data?.allowedCompany ? _.map(data?.allowedCompany, (item) => item.name) : [],
                animationType: data?.animationType,
                desktopImageKey: {
                    en: {
                        previewUrl: data?.desktopImageUrlEn,
                    },
                    zh: {
                        previewUrl: data?.desktopImageUrlZh
                    }
                },
                mobileImageKey: {
                    en: {
                        previewUrl: data?.mobileImageUrlEn,
                    },
                    zh: {
                        previewUrl: data?.mobileImageUrlZh
                    }
                },
                decorationImageKey: {
                    previewUrl: data?.decorationImageUrlEn,
                },
                decorationMobileImageKey: {
                    previewUrl: data?.decorationMobileImageUrlEn,
                },
                animationLine1: data?.animationLine1 || {},
                mobileAnimationLine1: data?.mobileAnimationLine1 || {},
                animationLine2: data?.animationLine2 || {},
                mobileAnimationLine2: data?.mobileAnimationLine2 || {},
                animationButton: data?.animationButton || {},
                mobileAnimationButton: data?.mobileAnimationButton || {},
                urlPath: data?.urlPath || {},
                mobileUrlPath: data?.mobileUrlPath || {},
            })
        }
    }, [data, bannerId])


    const mutation = useMutation({
        mutationFn: (payload) => updateBanner(bannerId, payload),
        onSuccess: (data) => {
            setSnackbar({
                type: 'success',
                message: 'Success'
            })
        },
        onError: (error) => {
            setSnackbar({
                type: 'error',
                message: `${error.code} ${error.errorMessage ? `- ${error.errorMessage?.en}` : ""}`
            })
        }
    })

    const handleOnSubmit = (formValue) => {
        console.log("formValue", formValue)
    }

    const Header = () => {
        return (
            <>
                <Typography bold style={{ flex: 1 }}>{`Public Banner `}</Typography>
            </>
        )
    }

    const CardHeader = () => {
        return (
            <div />
        )
        return (
            <Button
                style={{
                    alignSelf: 'flex-end',
                    margin: `0px 20px 10px 20px`,
                }}
                onClick={() => handleSubmit(handleOnSubmit)()}
                disabled={!canModify}
            >
                Save
            </Button>
        )
    }

    const isStaticType = data?.animationType && data.animationType === 'static' ? true : false
    const readOnly = true

    return (
        <PageWrapper
            loading={isLoading}
            renderHeader={Header}
            renderBackButton={() => <Button type='back' onClick={() => navigate(-1)} style={{ marginLeft: 10 }} />}
            renderCardHeader={CardHeader}
        >
            <Content>
                <Controller
                    name={`allowedCompany`}
                    control={control}
                    render={({ field }) => {
                        return (
                            <Select
                                title="Select Company"
                                multiple
                                direction="vertical"
                                options={companies}
                                value={field?.value}
                                onChange={(value) => {
                                    field.onChange(value)
                                }}
                                width="40%"
                                disabled={readOnly}
                            // error={errors?.company}
                            // helperText={errors?.company?.message}
                            />
                        )
                    }}
                />
                <div style={{ marginTop: 20 }} />
                <Controller
                    name={`animationType`}
                    control={control}
                    render={({ field }) => {
                        return (
                            <Select
                                title="Banner Type*"
                                direction="vertical"
                                options={[
                                    {
                                        name: "Animation1",
                                        _id: "animation1"
                                    },
                                    {
                                        name: "Animation2",
                                        _id: "animation2"
                                    },
                                    {
                                        name: "Static",
                                        _id: "static"
                                    }
                                ]}
                                value={field?.value}
                                onChange={(value) => {
                                    field.onChange(value)
                                }}
                                width="40%"
                                disabled={readOnly}
                            // error={errors?.company}
                            // helperText={errors?.company?.message}
                            />
                        )
                    }}
                />
                <GreyDivider style={{ margin: `20px 0px` }} />
                {
                    TabList.map((tabItem) => {
                        const isDesktop = tabItem.id === 'desktop'
                        return (
                            <>
                                <Typography variant="header" bold>{tabItem.name}</Typography>
                                <Grid container direction='row' style={{ padding: `20px 0px 40px 0px` }}>
                                    {
                                        Language.map((item, i) => {
                                            return (
                                                <FormContainer style={{ marginRight: i ? 0 : 20 }}>
                                                    <Grid container direction='row' style={{ marginBottom: 20 }}>
                                                        <Typography variant="header" style={{ flex: 1 }}>{item.label}</Typography>
                                                        {/* <DeleteIcon /> */}
                                                    </Grid>
                                                    <Controller
                                                        name={isDesktop ? `desktopImageKey.${item.id}` : `mobileImageKey.${item.id}`}
                                                        control={control}
                                                        render={({ field }) => {
                                                            return (
                                                                <ComponentContainer direction='vertical' title="Banner Image">
                                                                    <ImageUpload
                                                                        {...field}
                                                                        readOnly={readOnly}
                                                                    />
                                                                </ComponentContainer>
                                                            )
                                                        }}
                                                    />
                                                    {
                                                        isStaticType ?
                                                            <Controller
                                                                name={isDesktop ? `urlPath.${item.id}` : `mobileUrlPath.${item.id}`}
                                                                control={control}
                                                                render={({ field }) => {
                                                                    return (
                                                                        <TextField
                                                                            {...field}
                                                                            title="URL"
                                                                            direction="vertical"
                                                                            fullWidth
                                                                            readOnly={readOnly}
                                                                        // error={error}
                                                                        // helperText={error?.message}
                                                                        />
                                                                    )
                                                                }}
                                                            />
                                                            :
                                                            <>
                                                                <Controller
                                                                    name={isDesktop ? `animationLine1.${item.id}` : `mobileAnimationLine1.${item.id}`}
                                                                    control={control}
                                                                    render={({ field }) => {
                                                                        return (
                                                                            <TextField
                                                                                {...field}
                                                                                title="Line 1"
                                                                                direction="vertical"
                                                                                fullWidth
                                                                                readOnly={readOnly}
                                                                            // error={error}
                                                                            // helperText={error?.message}
                                                                            />
                                                                        )
                                                                    }}
                                                                />
                                                                <Controller
                                                                    name={isDesktop ? `animationLine2.${item.id}` : `mobileAnimationLine2.${item.id}`}
                                                                    control={control}
                                                                    render={({ field }) => {
                                                                        return (
                                                                            <TextField
                                                                                {...field}
                                                                                title="Line 2"
                                                                                direction="vertical"
                                                                                fullWidth
                                                                                readOnly={readOnly}
                                                                            // error={error}
                                                                            // helperText={error?.message}
                                                                            />
                                                                        )
                                                                    }}
                                                                />
                                                                <Controller
                                                                    name={isDesktop ? `decorationImageKey.` : `decorationMobileImageKey`}
                                                                    control={control}
                                                                    render={({ field }) => {
                                                                        return (
                                                                            <ComponentContainer direction='vertical' title={`Icon Image`}>
                                                                                <ImageUpload
                                                                                    {...field}
                                                                                    readOnly={readOnly}
                                                                                />
                                                                            </ComponentContainer>
                                                                        )
                                                                    }}
                                                                />
                                                                <Controller
                                                                    name={isDesktop ? `urlPath.${item.id}` : `mobileUrlPath.${item.id}`}
                                                                    control={control}
                                                                    render={({ field }) => {
                                                                        return (
                                                                            <TextField
                                                                                {...field}
                                                                                title="Button URL"
                                                                                direction="vertical"
                                                                                fullWidth
                                                                                readOnly={readOnly}
                                                                            // error={error}
                                                                            // helperText={error?.message}
                                                                            />
                                                                        )
                                                                    }}
                                                                />
                                                                <Controller
                                                                    name={isDesktop ? `animationButton.${item.id}` : `mobileAnimationButton.${item.id}`}
                                                                    control={control}
                                                                    render={({ field }) => {
                                                                        return (
                                                                            <TextField
                                                                                {...field}
                                                                                title="Button Text"
                                                                                direction="vertical"
                                                                                fullWidth
                                                                                readOnly={readOnly}
                                                                            // error={error}
                                                                            // helperText={error?.message}
                                                                            />
                                                                        )
                                                                    }}
                                                                />
                                                            </>
                                                    }

                                                </FormContainer>
                                            )
                                        })
                                    }
                                </Grid>
                            </>
                        )
                    })
                }

            </Content>
        </PageWrapper>
    )
}

export default BannerDetailPage;
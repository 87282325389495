import { useState, useEffect, createContext } from "react";
import { listCompany } from '../apis/company'
import {
    useQuery,
} from '@tanstack/react-query'

export const companiesContext = createContext();
function CompaniesProvider({ children }){
    const [companies, setCompanies] = useState(null);
    const [VIPcompanies, setVIPCompanies] = useState(null);
    const token = localStorage.getItem('access_token');

    const { data, isLoading, refetch: refetchCompanies } = useQuery({
        queryKey: ["allCompany"],
        queryFn: async () => await listCompany(),
        enabled: token ? true : false,
        // retry: false,
        onSuccess: (data) => {
            setCompanies(data)
        }
    });

    const { data: VIPdata, isLoading: VIPisLoading, refetch: refetchVIPCompanies } = useQuery({
        queryKey: ["vipCompany"],
        queryFn: async () => await listCompany({ params: { companyType: "vip", limit: 9999 } }),
        enabled: token ? true : false,
        // retry: false,
        onSuccess: (data) => {
            setVIPCompanies(data)
        }
    });

    return (
        <companiesContext.Provider value={{companies, setCompanies, VIPcompanies, setVIPCompanies, refetchCompanies, refetchVIPCompanies}}>
            {children}
        </companiesContext.Provider>
    )
}

export default CompaniesProvider
import { joiResolver } from '@hookform/resolvers/joi';
import { Grid } from '@mui/material';
import {
    useMutation
} from '@tanstack/react-query';
import Joi from 'joi';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { createBanner } from '../../apis/banner';
import {
    GreyDivider
} from '../../components';
import ImageUpload from '../../components/banner/ImageUpload';
import {
    Button,
    ComponentContainer,
    PageWrapper,
    Select,
    TextField,
    Typography
} from '../../components/share';
import { snackbarContext } from '../../context/SnackbarProvider';
import { companiesContext } from '../../context/CompaniesProvider';
import { removeEmptyElements, getPermission } from '../../utils';
import { TabList } from './index';
import { userContext } from '../../context/UserProvider';
import _ from 'lodash'
import constants from '../../constants';
import { styled as styledMui } from '@mui/material/styles';

const Content = styledMui('div')(
    ({ theme }) => `
    display: flex;
    flex-direction: column;
    margin: 20px;
    height: 100%;
    background: ${theme.palette.mainColor.light};
    border-radius: 5px;
    flex-wrap: nowrap;
    padding: 20px 40px;
    overflow: auto;
  `,
);

const FormContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: #fff;
    border-radius: 5px;
    padding: 20px;
    // height: 100%;
    
    > * {
        margin-bottom: 20px;
    }
`

export const schema = Joi.object({
    allowedCompany: Joi.array(),
    animationType: Joi.string().required(),
    desktopImageKey: Joi.object({
        en: Joi.any().required(),
        zh: Joi.any().required(),
    }).required(),
    mobileImageKey: Joi.object({
        en: Joi.any().required(),
        zh: Joi.any().required(),
    }).required(),
    decorationImageKey: Joi.object(),
    decorationMobileImageKey: Joi.object(),
    animationLine1: Joi.object({
        en: Joi.string().empty(""),
        zh: Joi.string().empty(""),
    }),
    mobileAnimationLine1: Joi.object({
        en: Joi.string().empty(""),
        zh: Joi.string().empty(""),
    }),
    animationLine2: Joi.object({
        en: Joi.string().empty(""),
        zh: Joi.string().empty(""),
    }),
    mobileAnimationLine2: Joi.object({
        en: Joi.string().empty(""),
        zh: Joi.string().empty(""),
    }),
    animationButton: Joi.object({
        en: Joi.string().empty(""),
        zh: Joi.string().empty(""),
    }),
    mobileAnimationButton: Joi.object({
        en: Joi.string().empty(""),
        zh: Joi.string().empty(""),
    }),
    urlPath: Joi.object({
        en: Joi.string().uri().allow(""),
        zh: Joi.string().uri().allow(""),
    }),
    mobileUrlPath: Joi.object({
        en: Joi.string().uri().allow(""),
        zh: Joi.string().uri().allow(""),
    }),

}).messages({
    "any.required": "Required",
    "object.base": "Required"
});

const Language = [
    {
        id: "en",
        label: "English"
    },
    {
        id: "zh",
        label: "Chinese"
    }
]

function BannerAddPage() {

    const navigate = useNavigate();
    const [snackbar, setSnackbar] = useContext(snackbarContext);
    const [user, setUser] = useContext(userContext);
    const { VIPcompanies } = useContext(companiesContext);
    const [tmpIconImages, setTmpIconImages] = useState([]);
    const [tmpBannerImages, setTmpBannerImages] = useState([]);

    const { canView, canModify } = getPermission(user?.permissions?.banner)

    const { control, handleSubmit, watch, formState: { errors }, reset, setValue, getValues } = useForm({
        resolver: joiResolver(schema),
    });

    const watchAnimationType = watch("animationType");

    const isStaticType = watchAnimationType === "static";

    useEffect(() => {
    if (!_.isEmpty(errors)) {
        setSnackbar({
            type: "error",
            message: constants.INVALID_INPUT_ERR_MSG
        })
    } 
    }, [errors])

    const mutation = useMutation({
        mutationFn: (payload) => createBanner(payload),
        onSuccess: (data) => {
            navigate(`/public-banner`);
            setSnackbar({
                type: 'success',
                message: 'Success'
            })
        },
        onError: (error) => {
            setSnackbar({
                type: 'error',
                message: `${error.code} ${error.errorMessage ? `- ${error.errorMessage?.en}` : ""}`
            })
        },
    })

    const handleOnSubmit = (formValue) => {
        // console.log("formValue", formValue);
        let payload = {}
        for (const property in formValue) {
            if (property === "desktopImageKey" || property === "mobileImageKey") {
                payload[property] = {
                    en: formValue[property]?.en?.fileKey,
                    zh: formValue[property]?.zh?.fileKey
                }
            } else if (property === "decorationImageKey" || property === "decorationMobileImageKey") {
                payload[property] = {
                    en: formValue[property]?.fileKey,
                    zh: formValue[property]?.fileKey
                }
            } else {
                payload[property] = formValue[property];
            }
        }
        payload = removeEmptyElements(payload)
        mutation.mutate(payload)
        console.log("payload", payload);
    }

    const Header = () => {
        return (
            <>
                <Typography bold style={{ flex: 1 }}>{`Public Banner > Add New`}</Typography>
            </>
        )
    }

    const CardHeader = () => {
        return (
            <Button
                style={{
                    alignSelf: 'flex-end',
                    margin: `0px 20px 10px 20px`,
                }}
                onClick={() => {
                    // console.log("getValues", getValues())
                    handleSubmit(handleOnSubmit)()
                }}
                disabled={!canModify}
            >
                Save
            </Button>
        )
    }

    console.log("errors", errors)

    return (
        <PageWrapper
            renderHeader={Header}
            renderBackButton={() => <Button type='back' onClick={() => navigate(-1)} style={{ marginLeft: 10 }} />}
            renderCardHeader={CardHeader}
        >
            <Content>
                <Controller
                    name={`allowedCompany`}
                    control={control}
                    render={({ field }) => {
                        return (
                            <Select
                                title="Select Company"
                                multiple
                                direction="vertical"
                                options={VIPcompanies}
                                value={field?.value}
                                onChange={(value) => {
                                    field.onChange(value)
                                }}
                                width="40%"
                                // disabled={readOnly}
                                error={errors?.allowedCompany}
                                helperText={errors?.allowedCompany?.message}
                            />
                        )
                    }}
                />
                <div style={{ marginTop: 20 }} />
                <Controller
                    name={`animationType`}
                    control={control}
                    render={({ field }) => {
                        return (
                            <Select
                                title="Banner Type*"
                                direction="vertical"
                                options={[
                                    {
                                        name: "Animation1",
                                        _id: "animation1"
                                    },
                                    {
                                        name: "Animation2",
                                        _id: "animation2"
                                    },
                                    {
                                        name: "Static",
                                        _id: "static"
                                    }
                                ]}
                                value={field?.value}
                                onChange={(value) => {
                                    field.onChange(value)
                                }}
                                width="40%"
                                // disabled={readOnly}
                                error={errors?.animationType}
                                helperText={errors?.animationType?.message}
                            />
                        )
                    }}
                />
                <GreyDivider style={{ margin: `20px 0px` }} />
                {
                    TabList.map((tabItem) => {
                        const isDesktop = tabItem.id === 'desktop'
                        return (
                            <>
                                <Typography variant="header" bold>{tabItem.name}</Typography>
                                <Grid container direction='row' style={{ padding: `20px 0px 40px 0px` }}>
                                    {
                                        Language.map((item, i) => {
                                            return (
                                                <FormContainer style={{ marginRight: i ? 0 : 20 }}>
                                                    <Grid container direction='row' style={{ marginBottom: 20 }}>
                                                        <Typography variant="header" style={{ flex: 1 }}>{item.label}</Typography>
                                                        {/* <DeleteIcon /> */}
                                                    </Grid>
                                                    <Controller
                                                        name={isDesktop ? `desktopImageKey.${item.id}` : `mobileImageKey.${item.id}`}
                                                        control={control}
                                                        render={({ field }) => {
                                                            return (
                                                                <ComponentContainer 
                                                                    direction='vertical' 
                                                                    title="Banner Image*"
                                                                    error={isDesktop ? errors?.desktopImageKey?.[item?.id] : errors?.mobileImageKey?.[item?.id]}
                                                                    helperText={isDesktop ? errors?.desktopImageKey?.[item?.id]?.message : errors?.mobileImageKey?.[item?.id]?.message}
                                                                >
                                                                    <ImageUpload
                                                                        {...field}
                                                                        images={tmpBannerImages}
                                                                        onChange={(v, images) => {
                                                                            field.onChange(v)
                                                                            setTmpBannerImages(images.filter((img) => img?.isUploadImage))
                                                                        }}
                                                                    />
                                                                </ComponentContainer>
                                                            )
                                                        }}
                                                    />
                                                    {
                                                        isStaticType ?
                                                            <Controller
                                                                name={isDesktop ? `urlPath.${item.id}` : `mobileUrlPath.${item.id}`}
                                                                control={control}
                                                                render={({ field }) => {
                                                                    const fieldName = isDesktop ? `urlPath` : `mobileUrlPath`
                                                                    return (
                                                                        <TextField
                                                                            {...field}
                                                                            title="URL"
                                                                            direction="vertical"
                                                                            fullWidth
                                                                            // readOnly={readOnly}
                                                                            error={errors?.[fieldName]?.[item.id]}
                                                                            helperText={errors?.[fieldName]?.[item.id]?.message}
                                                                        />
                                                                    )
                                                                }}
                                                            />
                                                            :
                                                            <>
                                                                <Controller
                                                                    name={isDesktop ? `animationLine1.${item.id}` : `mobileAnimationLine1.${item.id}`}
                                                                    control={control}
                                                                    render={({ field }) => {
                                                                        return (
                                                                            <TextField
                                                                                {...field}
                                                                                title="Line 1"
                                                                                direction="vertical"
                                                                                fullWidth
                                                                            // readOnly={readOnly}
                                                                            // error={error}
                                                                            // helperText={error?.message}
                                                                            />
                                                                        )
                                                                    }}
                                                                />
                                                                <Controller
                                                                    name={isDesktop ? `animationLine2.${item.id}` : `mobileAnimationLine2.${item.id}`}
                                                                    control={control}
                                                                    render={({ field }) => {
                                                                        return (
                                                                            <TextField
                                                                                {...field}
                                                                                title="Line 2"
                                                                                direction="vertical"
                                                                                fullWidth
                                                                            // readOnly={readOnly}
                                                                            // error={error}
                                                                            // helperText={error?.message}
                                                                            />
                                                                        )
                                                                    }}
                                                                />
                                                                <Controller
                                                                    name={isDesktop ? `decorationImageKey.` : `decorationMobileImageKey`}
                                                                    control={control}
                                                                    render={({ field }) => {
                                                                        return (
                                                                            <ComponentContainer direction='vertical' title="Icon Image">
                                                                                <ImageUpload
                                                                                    {...field}
                                                                                    images={tmpIconImages}
                                                                                    onChange={(v, images) => {
                                                                                        field.onChange(v)
                                                                                        setTmpIconImages(images.filter((img) => img?.isUploadImage))
                                                                                    }}
                                                                                />
                                                                            </ComponentContainer>
                                                                        )
                                                                    }}
                                                                />
                                                                <Controller
                                                                    name={isDesktop ? `urlPath.${item.id}` : `mobileUrlPath.${item.id}`}
                                                                    control={control}
                                                                    render={({ field }) => {
                                                                        const fieldName = isDesktop ? `urlPath` : `mobileUrlPath`
                                                                        return (
                                                                            <TextField
                                                                                {...field}
                                                                                title="Button URL"
                                                                                direction="vertical"
                                                                                fullWidth
                                                                                // readOnly={readOnly}
                                                                                error={errors?.[fieldName]?.[item.id]}
                                                                                helperText={errors?.[fieldName]?.[item.id]?.message}
                                                                            />
                                                                        )
                                                                    }}
                                                                />
                                                                <Controller
                                                                    name={isDesktop ? `animationButton.${item.id}` : `mobileAnimationButton.${item.id}`}
                                                                    control={control}
                                                                    render={({ field }) => {
                                                                        return (
                                                                            <TextField
                                                                                {...field}
                                                                                title="Button Text"
                                                                                direction="vertical"
                                                                                fullWidth
                                                                            // readOnly={readOnly}
                                                                            // error={error}
                                                                            // helperText={error?.message}
                                                                            />
                                                                        )
                                                                    }}
                                                                />
                                                            </>
                                                    }

                                                </FormContainer>
                                            )
                                        })
                                    }
                                </Grid>
                            </>
                        )
                    })
                }

            </Content>
        </PageWrapper>
    )
}

export default BannerAddPage;